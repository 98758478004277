
import * as React from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'

// Create URL path for numeric pagination
const getPageNumberPath = (currentIndex, basePath) => {
  if (currentIndex === 1) {
    return basePath
  }
  
  return `${basePath}/page-${(currentIndex)}`
}

// Create an object array of pagination numbers.
const getPaginationGroup = (basePath, currentPage, pageCount, noOfPagesNos = 5) => {
    let startPage = currentPage;

    if (startPage === 1 || startPage === 2 || pageCount < noOfPagesNos)
        startPage = 1;
    else
        startPage -= 2;

    let maxPage = startPage + noOfPagesNos;

    if (pageCount < maxPage) {
        maxPage = pageCount + 1
    }

    if (maxPage - startPage !== noOfPagesNos && maxPage > noOfPagesNos) {
        startPage = maxPage - noOfPagesNos;
    }

    let paginationInfo = [];

    for (let i = startPage; i < maxPage; i++) {        
        paginationInfo.push({
            number: i,
            url: getPageNumberPath(i, basePath),
            isCurrent: currentPage === i
        });
    }

    return paginationInfo;
};

export const Pagination = ({ pageInfo, basePath }) => {
    if (!pageInfo) 
        return null

    const { currentPage, pageCount } = pageInfo

    // Create URL path for previous and next buttons
    const prevPagePath = currentPage === 2 ? basePath : `${basePath}/page-${(currentPage - 1)}`
    const nextPagePath = `${basePath}/page-${(currentPage + 1)}`
    
    if (pageCount > 1) { 
        return (
            <nav className="c-pagination" aria-label="Pagination">
                <div className="container">
                    <div className="row">
                        <span className="u-visually-hide" id="label-pagination">Pagination</span>
                        <div className="c-pagination__container" aria-labelledby="label-pagination">
                            <ol className="c-pagination__items">
                                {currentPage > 1 ? 
                                <li className="c-pagination__item c-pagination__item--prev">
                                    <Link to={prevPagePath} className="c-pagination__link">
                                        <svg fill="none" viewBox="0 0 20 20" className="c-pagination__link-icon"><path fillRule="evenodd" clipRule="evenodd" d="M9.593 0 11 1.467 2.813 10 11 18.533 9.593 20 0 10.001 9.593 0Z" fill="#1A1A1A"/><path fill="#1A1A1A" d="M20 9v2H2V9z"/></svg>
                                        <span className="u-visually-hide">Go to</span>
                                        <span className="c-pagination__link-text"> Previous </span>
                                        <span className="u-visually-hide">page</span>
                                    </Link>
                                </li> : null}       
                                {getPaginationGroup(basePath, currentPage, pageCount).map((item, i) => {
                                    return (
                                        <li key={i} className="c-pagination__item">
                                            <Link to={item.url} className={`c-pagination__link ${item.isCurrent ?  "is-current" : ""}`}>
                                                <span className="u-visually-hide">Go to page</span> {item.number}
                                            </Link>
                                        </li>
                                    )
                                })}
                                {currentPage !== pageCount ?
                                    <li className="c-pagination__item c-pagination__item--next">
                                        <Link to={nextPagePath} className="c-pagination__link">
                                            <span className="u-visually-hide">Go to</span>
                                            <span className="c-pagination__link-text"> Next </span>
                                            <span className="u-visually-hide">page</span>
                                            <svg fill="none" viewBox="0 0 20 20" className="c-pagination__link-icon"><path fillRule="evenodd" clipRule="evenodd" d="M10.407 20 9 18.533 17.187 10 9 1.467 10.407 0 20 9.999 10.407 20Z" fill="#1A1A1A"/><path fill="#1A1A1A" d="M0 11V9h18v2z"/></svg>
                                        </Link>
                                    </li> : null}
                            </ol>
                        </div>
                    </div>
                </div>
            </nav>
        )
    }
    else {
        return null
    }
  }

Pagination.propTypes = {
    pageInfo: PropTypes.object,
    basePath: PropTypes.string
}

export default Pagination;